<template>
  <custom-collapse
    class="assignment-courier-group"
    :isCourierGroupHeader="true"
    open>
    <div
      slot="header"
      class="courier-group-name">
      {{ courierGroup.name }}
    </div>
    <div class="assignment-courier-group-couriers">
      <assignment-courier
        v-for="courier in courierGroup.couriers"
        :key="`assignment_courier_group_courier_${courier.id}`"
        :courier="courier"
        :isThirdParty="courierGroup.isThirdParty"
        @trip-contextmenu="(event, trip) => $emit('trip-contextmenu', event, trip)"
        @task-contextmenu="(event, task) => $emit('task-contextmenu', event, task)"/>
    </div>
  </custom-collapse>
</template>

<script>
const AssignmentCourier = () => import('./AssignmentCourier')
const CustomCollapse = () => import('@components/CustomCollapse')

export default {
  name: 'assignment-courier-group',
  components: {
    AssignmentCourier,
    CustomCollapse
  },
  props: {
    courierGroup: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-courier-group {
  &.open {
    &::v-deep .custom-collapse-header {
      font-weight: normal;
      color: $grey-dark;
      background-color: $grey-darkest;
    }
  }
}
</style>
